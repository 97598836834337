// hooks/useSessions.ts
import { useState, useEffect } from 'react';
import { getSessions, PagedResponse, SessionView } from '../SvcService';

export function useSessions(page: number, pageSize: number, groupFilter: string) {
  const [data, setData] = useState<PagedResponse<SessionView> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    getSessions(page, pageSize, groupFilter)
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message || 'Error fetching sessions');
        setLoading(false);
      });
  }, [page, pageSize, groupFilter]);

  return { data, loading, error };
}
