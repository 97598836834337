// components/SessionTable.tsx
import React from 'react';
import { SessionView } from '../SvcService';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Paper,
} from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface SessionTableProps {
  sessions: SessionView[];
  editingSessionId: string | null;
  editedName: string;
  onEditClick: (sessionId: string, currentName: string) => void;
  onSaveClick: (sessionId: string) => void;
  onCancelClick: () => void;
  onNameChange: (name: string) => void;
}

export const SessionTable: React.FC<SessionTableProps> = ({
  sessions,
  editingSessionId,
  editedName,
  onEditClick,
  onSaveClick,
  onCancelClick,
  onNameChange,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="sessions table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Session Id</TableCell>
            <TableCell align="center">Group Name</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Item Count</TableCell>
            <TableCell align="center">Character Count</TableCell>
            <TableCell align="center">Created At</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map((session) => (
            <TableRow key={session.sessionId}>
              <TableCell align="center">
                <Link to={`/log/${session.sessionId}`}>
                  {session.sessionId}
                </Link>
              </TableCell>
              <TableCell align="center">{session.groupName}</TableCell>
              <TableCell align="center">
                {editingSessionId === session.sessionId ? (
                  <TextField
                    value={editedName}
                    onChange={(e) => onNameChange(e.target.value)}
                  />
                ) : (
                  session.name
                )}
              </TableCell>
              <TableCell align="center">{session.itemCount}</TableCell>
              <TableCell align="center">{session.characterCount}</TableCell>
              <TableCell align="center">
                {session.createAtUtc.toLocaleString()}
              </TableCell>
              <TableCell align="center">
                {editingSessionId === session.sessionId ? (
                  <>
                    <IconButton onClick={() => onSaveClick(session.sessionId)}>
                      <CheckIcon />
                    </IconButton>
                    <IconButton onClick={onCancelClick}>
                      <CloseIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton onClick={() => onEditClick(session.sessionId, session.name)}>
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
