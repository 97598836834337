import axios, { AxiosError } from 'axios';
import { ApiErrorResponse } from './ApiResponseInterfaces';

export class ApiError extends Error {
  public statusCode?: number;
  public errorCode?: string;
  public details?: Record<string, string>;
  public timestamp?: string;
  
  constructor(message: string, statusCode?: number, errorCode?: string, details?: Record<string, string>, timestamp?: string) {
    super(message);
    this.name = 'ApiError';
    this.statusCode = statusCode;
    this.errorCode = errorCode;
    this.details = details;
    this.timestamp = timestamp;
  }
}

/**
 * Handles API errors from axios responses
 * @param error The error from axios
 * @returns A formatted ApiError or rethrows the original error
 */
export function handleApiError(error: unknown): never {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<ApiErrorResponse>;
    
    if (axiosError.response?.data) {
      const errorResponse = axiosError.response.data;
      
      throw new ApiError(
        errorResponse.message || 'An unknown error occurred',
        axiosError.response.status,
        errorResponse.errorCode,
        errorResponse.details,
        errorResponse.timestamp
      );
    }
    
    // Fallback for network errors
    if (axiosError.code === 'ECONNABORTED' || !axiosError.response) {
      throw new ApiError('Network error. Please check your connection.', 0, 'NETWORK_ERROR');
    }
    
    // Generic HTTP error
    throw new ApiError(
      `HTTP error ${axiosError.response?.status}`,
      axiosError.response?.status
    );
  }
  
  // Rethrow non-axios errors
  throw error;
}

/**
 * Wraps a promise or a function that returns a promise to handle API errors
 */
export async function withErrorHandling<T>(
  promiseOrFn: Promise<T> | (() => Promise<T>)
): Promise<T> {
  try {
    // If it's a function that returns a promise, call it
    if (typeof promiseOrFn === 'function') {
      return await (promiseOrFn as () => Promise<T>)();
    }
    // Otherwise, it's already a promise
    return await promiseOrFn;
  } catch (error) {
    throw handleApiError(error);
  }
}