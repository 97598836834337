import axios from 'axios';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from './msalConfig';
import { ApiSuccessResponse, extractData } from './ApiResponseInterfaces';
import { withErrorHandling } from './ApiErrorHandling';

const serviceApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

async function getAccessToken() {
    if (serviceApi.defaults.headers.common['Authorization']) {
        return;
    }

    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    const account = msalInstance.getAllAccounts()[0];
    const tokenRequest = {
        account,
        scopes: loginRequest.scopes // Replace with your API scopes
    }
    try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        let accessToken = response.accessToken;
        serviceApi.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        return;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            if (msalInstance) {
                msalInstance.acquireTokenRedirect(tokenRequest);
            }
        }
    }
    try {
        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        return response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            if (msalInstance) {
                msalInstance.acquireTokenRedirect(tokenRequest);
            }
        }
        throw error;
    }
}

// Define the type that matches your backend CreateSessionResponse
export interface CreateSessionResponse {
    sessionId: string;
}

export async function createSession(): Promise<string> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const response = await serviceApi.post<ApiSuccessResponse<CreateSessionResponse>>('/api/sessions/create');
        // Extract sessionId from the response data
        return extractData<CreateSessionResponse>(response).sessionId;
    });
}

// Define the upload URL response type
export interface UploadUrlResponse {
    uploadUrl: string;
}

export async function uploadFile(sessionId: string, file: File) {
    try {
        await getAccessToken();
        const updateSessionRequest = {
            SessionId: sessionId,
            FileName: file.name,
        };

        return withErrorHandling(async () => {
            const response = await serviceApi.put<ApiSuccessResponse<UploadUrlResponse>>('/api/sessions/item/add', updateSessionRequest);
            const { uploadUrl } = extractData<UploadUrlResponse>(response);

            if (!uploadUrl) {
                throw new Error('No upload URL received.');
            }

            const uploadResponse = await fetch(uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type || 'application/octet-stream',
                    'x-ms-blob-type': 'BlockBlob',
                },
                body: file,
                mode: 'cors',
            });

            if (!uploadResponse.ok) {
                throw new Error(`Upload failed with status ${uploadResponse.status}`);
            }

            return uploadResponse;
        });
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
}

// Generic paging response
export interface PagedResponse<T> {
    items: T[];
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
    hasNextPage: boolean;
}

export interface SessionView {
    sessionId: string;
    groupName: string; 
    name: string; 
    itemCount: number;
    characterCount: number;
    createAtUtc: Date;
}

// Define session type for API response
export interface ApiSessionItem {
    sessionId: string;
    groupName: string;
    name: string;
    itemCount: number;
    characterCount: number;
    createAtUtc: string;
}

// Paging-enabled getSessions service call.
export async function getSessions(
    page: number = 1,
    pageSize: number = 100,
    groupName?: string
  ): Promise<PagedResponse<SessionView>> {
    await getAccessToken();
    return withErrorHandling(async () => {
        // Build query params. Exclude groupName if it's "All" or empty.
        const params: any = { page, pageSize };
        if (groupName && groupName !== 'All') {
            params.groupName = groupName;
        }
        const response = await serviceApi.get<ApiSuccessResponse<PagedResponse<ApiSessionItem>>>('/api/sessions/all', { params });
        const pagedResponse = extractData<PagedResponse<ApiSessionItem>>(response);
        
        return {
            items: pagedResponse.items.map((session) => ({
                sessionId: session.sessionId,
                groupName: session.groupName,
                name: session.name,
                itemCount: session.itemCount,
                characterCount: session.characterCount,
                createAtUtc: new Date(session.createAtUtc),
            })),
            page: pagedResponse.page,
            pageSize: pagedResponse.pageSize,
            totalCount: pagedResponse.totalCount,
            totalPages: pagedResponse.totalPages,
            hasNextPage: pagedResponse.hasNextPage,
        };
    });
}

export interface SessionItemView {
    id: string;
    fileName: string;
    audioUrl: string;
    transcription: string;
    status: string;
    createdAtUtc: Date;
}

export interface SessionDetailView {
    sessionId: string;
    groupName: string;
    name: string;
    itemCount: number;
    characterCount: number;
    createAtUtc: Date;
    items: SessionItemView[];
}

// API response types
export interface ApiSessionItemView {
    id: string;
    fileName: string;
    audioUrl: string;
    transcription: string;
    status: string;
    createdAtUtc: string;
}

export interface ApiSessionDetailView {
    sessionId: string;
    groupName: string;
    name: string;
    itemCount: number;
    characterCount: number;
    createAtUtc: string;
    items: ApiSessionItemView[];
}

export async function getSessionDetails(sessionId: string): Promise<SessionDetailView> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const response = await serviceApi.get<ApiSuccessResponse<ApiSessionDetailView>>(`/api/sessions/by-id/${sessionId}`);
        const session = extractData<ApiSessionDetailView>(response);
        
        return {
            sessionId: session.sessionId,
            groupName: session.groupName, 
            name: session.name, 
            itemCount: session.itemCount,
            characterCount: session.characterCount,
            createAtUtc: new Date(session.createAtUtc),
            items: session.items.map((item) => ({
                id: item.id,
                fileName: item.fileName,
                transcription: item.transcription,
                audioUrl: item.audioUrl,
                status: item.status,
                createdAtUtc: new Date(item.createdAtUtc),
            })),
        };
    });
}

export interface UsageEOMView {
    id: string;
    eom: Date;
    characterCount: string;
}

export interface ApiUsageEOMView {
    id: string;
    eom: string;
    characterCount: string;
}

export async function getUsageByEOM(): Promise<UsageEOMView[]> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const response = await serviceApi.get<ApiSuccessResponse<ApiUsageEOMView[]>>('/api/usage/by_eom');
        const usageData = extractData<ApiUsageEOMView[]>(response);
        
        return usageData.map((usage) => ({
            id: usage.id,
            eom: new Date(usage.eom),
            characterCount: usage.characterCount,
        }));
    });
}

export async function updateSessionName(sessionId: string, name: string): Promise<void> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const updateNameRequest = {
            SessionId: sessionId,
            Name: name,
        };
        await serviceApi.put('/api/sessions/update/name', updateNameRequest);
    });
}

export async function updateSessionGroupName(sessionId: string, groupName: string): Promise<void> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const updateGroupNameRequest = {
            SessionId: sessionId,
            GroupName: groupName,
        };
        await serviceApi.put('/api/sessions/update/group-name', updateGroupNameRequest);
    });
}

export interface GroupInfo {
    groupId: string;
    groupName: string;
}

export async function getSessionGroups(): Promise<GroupInfo[]> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const response = await serviceApi.get<ApiSuccessResponse<GroupInfo[]>>('/api/sessions/group-info');
        return extractData<GroupInfo[]>(response);
    });
}

export async function addSessionGroup(groupName: string): Promise<void> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const groupNameAddRequest = {
            GroupName: groupName,
        };
        await serviceApi.post('/api/sessions/group-info/add', groupNameAddRequest);
    });
}

export interface UsageSummary {
    startDate: string;
    endDate: string;
    totalCharacters: number;
    totalItems: number;
    detailedUsage: Array<{
        period: string;
        characters: number;
        items: number;
        lastUpdated: string;
    }>;
}

export async function getUsageSummary(
    startDate?: string,
    endDate?: string
): Promise<UsageSummary> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const params: any = {};
        if (startDate) params.startDate = startDate;
        if (endDate) params.endDate = endDate;
        
        const response = await serviceApi.get<ApiSuccessResponse<UsageSummary>>('/api/usage/summary', { params });
        return extractData<UsageSummary>(response);
    });
}

export interface BillingProjection {
    currentDate: string;
    currentMonth: string;
    daysInMonth: number;
    currentDay: number;
    ratePerThousandCharacters: number;
    currentUsage: {
        characters: number;
        cost: number;
    };
    dailyAverage: {
        characters: number;
        cost: number;
    };
    monthProjection: {
        characters: number;
        cost: number;
    };
}

export async function getBillingProjection(rate?: number): Promise<BillingProjection> {
    await getAccessToken();
    return withErrorHandling(async () => {
        const params: any = {};
        if (rate) params.rate = rate;
        
        const response = await serviceApi.get<ApiSuccessResponse<BillingProjection>>('/api/usage/billing-projection', { params });
        return extractData<BillingProjection>(response);
    });
}