// hooks/useSessionGroups.ts
import { useState, useEffect } from 'react';
import { getSessionGroups, GroupInfo } from '../SvcService';

export function useSessionGroups() {
  const [groups, setGroups] = useState<GroupInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getSessionGroups()
      .then((response) => {
        setGroups(response);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message || 'Error fetching groups');
        setLoading(false);
      });
  }, []);

  return { groups, loading, error };
}
