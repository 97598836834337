import { homePage, theCurb } from './Constants';
import { LogLevel } from "@azure/msal-browser";


export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SIGNUP_SIGNIN",
    signUp: "B2C_1_SIGNUP",
    forgotPassword: "b2c_1_reset",
    editProfile: "b2c_1_edit_profile"
  },
  authorities: {
    signUpSignIn: {
      authority:  `https://${process.env.REACT_APP_MSAL_INSTANCE}/${process.env.REACT_APP_MSAL_TENANT_NAME}/B2C_1_SIGNUP_SIGNIN`,
      scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`]
    },
    signUp: {
      authority:  `https://${process.env.REACT_APP_MSAL_INSTANCE}/${process.env.REACT_APP_MSAL_TENANT_NAME}/B2C_1_SIGNUP`,
      scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`]
    },
    forgotPassword: {
      authority: `https://${process.env.REACT_APP_MSAL_INSTANCE}/${process.env.REACT_APP_MSAL_TENANT_NAME}/b2c_1_reset`,
      scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`]
    },
    editProfile: {
      authority: `https://${process.env.REACT_APP_MSAL_INSTANCE}/${process.env.REACT_APP_MSAL_TENANT_NAME}/b2c_1_edit_profile`,
      scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`]
    }
  },
  authorityDomain: `${process.env.REACT_APP_MSAL_INSTANCE}`
}

export const signupRequest = {
  authority: b2cPolicies.authorities.signUp.authority,
  scopes: b2cPolicies.authorities.signUp.scopes,
  redirectUri: homePage
  
};


export const loginRequest = {
  scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`],
  redirectUri: homePage
};

export const logoutRequest = {
  postLogoutRedirectUri: theCurb
};

export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_MSAL_CLIENT_ID}`,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri: `${process.env.REACT_APP_MSAL_REDIRECT_URL}`,
    knownAuthorities: [b2cPolicies.authorityDomain],
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored, localStorage is more durable but will make your user sign-in again if they close the tab, sessionStorage will keep the user signed in if they close the tab and come back within a certain period of time.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
