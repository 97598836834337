// Define interfaces that match the C# ApiResponse structure
export interface ApiResponse {
  timestamp: string;
  successful: boolean;
  requestId?: string;
}

export interface ApiSuccessResponse<T> extends ApiResponse {
  message?: string;
  data: T;
}

export interface ApiErrorResponse extends ApiResponse {
  message: string;
  errorCode?: string;
  details?: Record<string, string>;
}

// Helper function to extract data from ApiSuccessResponse with proper typing
export function extractData<T extends object>(response: { 
  data: ApiSuccessResponse<T> 
} | ApiSuccessResponse<T>): T {
  if ('data' in response && 'successful' in response.data) {
    // This is an Axios response containing an ApiSuccessResponse
    return response.data.data;
  }
  // This is already an ApiSuccessResponse
  return (response as ApiSuccessResponse<T>).data;
}