// pages/Logs.tsx
import React, { useState, useCallback, useMemo } from 'react';
import {
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Pagination,
  CircularProgress,
  Typography,
} from '@mui/material';
import { SessionView, updateSessionName } from '../SvcService';
import { SessionTable } from '../Components/SessionTable';
import { useSessions } from '../Hooks/useSessions';
import { useSessionGroups } from '../Hooks/useSessionGroups';

const Logs: React.FC = () => {
  // Paging state
  const [page, setPage] = useState<number>(1);
  const pageSize = 10; // Fixed for now; you can add a control to change this if desired.
  const [groupFilter, setGroupFilter] = useState<string>('All');

  // Editing state
  const [editingSessionId, setEditingSessionId] = useState<string | null>(null);
  const [editedName, setEditedName] = useState<string>('');

  // Use our custom hook to fetch sessions
  const { data, loading, error } = useSessions(page, pageSize, groupFilter);
  const sessions: SessionView[] = useMemo(() => data?.items || [], [data]);
  const totalPages = data?.totalPages || 1;

  // Use our custom hook to fetch group names.
  const { groups, loading: groupsLoading, error: groupsError } = useSessionGroups();
  // Create a drop-down list with "All" as the first item.
  const groupNames = useMemo(() => {
    const names = groups.map((group) => group.groupName);
    return ['All', ...names];
  }, [groups]);

  // Memoized callbacks
  const handleEditClick = useCallback((sessionId: string, currentName: string) => {
    setEditingSessionId(sessionId);
    setEditedName(currentName);
  }, []);

  const handleSaveClick = useCallback(async (sessionId: string) => {
    await updateSessionName(sessionId, editedName);
    // Optionally, you might want to trigger a refetch here.
    setEditingSessionId(null);
  }, [editedName]);

  const handleCancelClick = useCallback(() => {
    setEditingSessionId(null);
    setEditedName('');
  }, []);

  const handleNameChange = useCallback((name: string) => {
    setEditedName(name);
  }, []);

  const handleGroupFilterChange = useCallback((event: SelectChangeEvent<string>) => {
    setGroupFilter(event.target.value);
    setPage(1); // Reset to page 1 when filter changes.
  }, []);

  const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, width: '90%' }}>
      <Paper
        elevation={1}
        sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Paper
          elevation={6}
          sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}
        >
          <FormControl sx={{ mb: 2, minWidth: 200 }}>
            <InputLabel id="group-filter-label">Group Name</InputLabel>
            <Select
              labelId="group-filter-label"
              id="group-filter"
              value={groupFilter}
              label="Group Name"
              onChange={handleGroupFilterChange}
            >
              {groupsLoading ? (
                <MenuItem value="All">Loading...</MenuItem>
              ) : groupsError ? (
                <MenuItem value="All">Error loading groups</MenuItem>
              ) : (
                groupNames.map((group, idx) => (
                  <MenuItem key={idx} value={group}>
                    {group}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Paper>
        <Paper
          elevation={6}
          sx={{ width: '90%', p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          {loading ? (
            <CircularProgress />
          ) : error ? (
            <Typography color="error">Error: {error}</Typography>
          ) : (
            <>
              <SessionTable
                sessions={sessions}
                editingSessionId={editingSessionId}
                editedName={editedName}
                onEditClick={handleEditClick}
                onSaveClick={handleSaveClick}
                onCancelClick={handleCancelClick}
                onNameChange={handleNameChange}
              />
              <Pagination
                sx={{ mt: 2 }}
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                color="primary"
              />
            </>
          )}
        </Paper>
      </Paper>
    </Box>
  );
};

export default Logs;
